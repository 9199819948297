.login-page {
    background-color: #E7ECF3;
}

.login-container {
    max-width: 580px;
    margin: auto;
    font-family: "Source Sans Pro";
}

.mystartcloud-welcome {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: 60px;
    .mystartcloud-logo {
        height: 80px;
        width: auto;
        margin-bottom: 20px;
    }

    .mystartcloud-welcome-text {
        font-size: 32px;
        color: #081222;
    }

    margin-bottom: 20px;
}

$school-image-size: 60px;
.login-box {
    display: block;
    background-color: #FFFFFF;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 15px;
    .login-box-content {
        //max-width: 65%;
        max-width: 410px;
        padding-left: 25px;
        padding-right: 25px;
        margin: auto;
        .school-logo-name {

            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .school-logo {
                width: $school-image-size;
                height: $school-image-size;
                border-radius: $school-image-size / 2;
                margin-right: 20px;
            }
            .school-name {
                font-size: 18px;
                font-weight: 600;
            }
        }

        .login-form {
            .form-group {
                label {
                    font-size: 14px;
                    color: #4D4D4D;
                    margin-bottom: 6px;
                    font-weight: 600;
                }
            }

            .btn {
                font-size: 14px;
                font-weight: 500;
            }
        }

        .btn-success{
            color: #fff;
            background-color: #7AC74F;
            border-color: #7AC74F;
        }

        .btn-primary{
            color: #fff;
            background-color: #234F94;
            border-color: #234F94;
        }

        .alert {
            margin-bottom: 0;
            margin-top: 15px;
        }

    }
}
.modal-login-forgot-password {
    margin-top: 20px;
    a {
        font-size: 14px;
        &, &:hover, &:active {
            color: #ff0000;
            text-decoration: none;
        }
    }
}
